import React from 'react';
import Layout from '../components/layout';
import Seo from "../components/seo"
import CTA from '../components/cta';

import BlogFeed from '../components/blog-feed';

import patternHex from '../images/backrounds/pattern-hex-white.jpg';
import NeedAQuote from "../components/need-a-quote";
import {StaticImage} from "gatsby-plugin-image";
import Quote from "../components/quote";


const Index = ({location}) => (
  <Layout>
    <Seo
      pathName={location.pathname}
      title="Zendesk Multibrand"
      description="Zendesk multibrand is a powerful feature that allows you to manage all of your brands under one roof. Let us help you ensure a proper, efficient setup."
    />
    <section>
      <div
        className="hero"
        style={{
          backgroundImage: `url(${patternHex})`
        }}
      >
        <div className="hero-title bg-dark-transparent">
          <div className="hero-image">
            <StaticImage
              src="../images/clients/zendesk.png"
              alt="Zendesk Implementation Premier Partner"
              layout="constrained"
              style={{ maxWidth: "200px"}}
            />
          </div>
          <h1 className="text-white">
            set up multibrand in zendesk for your business
          </h1>
          <p className="text-white">
            If you have a complex arrangement of websites and sub-brands but want to manage all your data in one place and have a single Customer experience tool you may need to use Zendesk Multibrand.</p>
          <CTA href="/contact-us/" className="btn btn-primary" text="Tell Us About Your Project"/>
        </div>
      </div>
    </section>
    <section>
      <div className="container py-6">
        <div className="row">
          <div className="col">
            <h2>we can implement the best architecture and infrastructure to manage your customer success</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 pb-4 pb-lg-0">
            <h3>analyze and review</h3>
            <p>The first step would be a clear and careful analysis of requirements for all your businesses and divisions to make sure of the best architecture for you. Multibrand is an exceptional way to both segment and consolidate data so agents only see what they should. Managers can get the reporting and oversight they require and executive leadership can see a 360 view of customer experience across the whole business.</p>
          </div>
          <div className="col-lg-6">
            <h3>we can help with</h3>
            <ul className="text-primary m-0 pl-3">
              <li><span className="text-dark">Deploying cloned chat to multiple websites</span></li>
              <li><span className="text-dark">Using widgets to segment customer channels in multiple eCommerce stores</span></li>
              <li><span className="text-dark">Set multiple agents views</span></li>
              <li><span className="text-dark">Teach admins how to create automations for their teams</span></li>
              <li><span className="text-dark">Build custom reporting to manage SLAs</span></li>
              <li><span className="text-dark">Integrate Zendesk with all your other business tools</span></li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section className="bg-dark">
      <div className="container py-6">
        <div className="row">
          <div className="col">
            <h2 className="text-center text-white">Testimonials</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 pb-4 pb-lg-0">
            <Quote
              dark
              name="Bruno Larvol"
              company="Larvol"
              quote="They did a fine job of guiding us through the process and meeting our expectations."
            />
          </div>
          <div className="col-lg-4 pb-4 pb-lg-0">
            <Quote
              dark
              name="Noopur Shankar"
              company="Optimal Strategies"
              quote="Their inputs and suggestions were very helpful in steering our project in the right direction."
            />
          </div>
          <div className="col-lg-4">
            <Quote
              dark
              name="Don Jones"
              company="Venture Deals  "
              quote="The 729 team has a 'can-do, work the issue' approach to problem-solving and provides possible solutions quickly."
            />
          </div>
        </div>
      </div>
    </section>
    <NeedAQuote />
    <BlogFeed containerClassname="py-6"/>
  </Layout>
);

export default Index;
